

#layoutSidenav #layoutSidenav_nav{
    @media (min-width: 0px){
        transform: translateX(0) !important;
    }
}

#layoutSidenav #layoutSidenav_content{
    margin-left: 0px !important;
}

// @media (max-width: 950px){
//     #layoutSidenav_nav{
//         width: fit-content;
//     }
//     .nav-link{
//         span{
//             display: none;
//         }
//     }
//     .sb-sidenav-menu-heading{
//         display: none;
//     }
// }