.navbar-brand img {
	height: 50px;
}
.sidebar-btns {
	opacity: 0;
}
@media only screen and (max-width: 900px) {
	button {
		opacity: 1;
	}
}
