.react-datepicker-wrapper {
  input {
    display: block;
    font-size: 15px;
    // padding: 10px;
    border: none;
    width: 70%;
    padding-right: 0px;
    // border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    &:focus {
      outline: none;
    }
  }
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.col {
  label {
    img {
      margin-left: -45px;
    }
  }
}

.form-inline {
  h6 {
    padding-left: 10px;
  }
}
